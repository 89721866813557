@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

:root {
  --background-color: rgb(0, 1, 34);
  --font-family-inter: "Inter", sans-serif;
  --font-family-right: "Righteous", cursive;
  --default-color: rgb(206, 206, 206);
  --under-color: rgb(168, 168, 168);
}

body {
  background-color: var(--background-color);
  font-family: var(--font-family-inter);
  color: var(--default-color);
}

/* Navbar */
.a-nav-link {
  position: relative;
  text-decoration: none;
  color: var(--default-color);
  cursor: pointer;
  font-size: 12px;
}

.a-nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: var(--default-color);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.a-nav-link:hover::after {
  transform: scaleX(1);
}

/* Form  */
.a-form-input {
  width: 100%;
  border-radius: 5px;
  outline: none;
  background-color: rgba(243, 243, 243, 0.173);
  /* box-shadow: 0px 0px 3px rgba(83, 83, 83, 0.75), 0px 0px 15px rgba(0, 0, 0, 0.75); */
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.544);
}

.a-form-input:focus {
  border-color: rgb(219, 219, 219);
  box-shadow: 0px 0px 3px rgba(161, 161, 161, 0.75), 0px 0px 15px rgba(41, 41, 41, 0.75);
}

.a-form-input:disabled {
  cursor: not-allowed;
  background-color: rgb(38, 39, 63);
  border-color: rgba(255, 255, 255, 0.544);
}

.a-form-input::-webkit-input-placeholder {
  color: rgb(120, 120, 120);
}

.a-form-input:-moz-placeholder {
  color: rgb(120, 120, 120);
}

.a-form-input:-ms-input-placeholder {
  color: rgb(120, 120, 120);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  touch-action: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  touch-action: none;
}

.a-field-description::before {
  content: "* ";
}

/* Color  */
.a-bg-default {
  background-color: var(--background-color);
}

.a-color-under {
  color: var(--under-color);
}

/* Components  */
.promo {
  transform: skew(-110deg);
}

/* Font  */
.a-font-right {
  font-family: var(--font-family-right);
}

/* Animate */
.nav-animate-in {
  animation: 300ms ease-in-out navAnimateIn;
}

.nav-animate-out {
  animation: 150ms ease-in-out navAnimateOut;
}

@keyframes navAnimateIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes navAnimateOut {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.a-item > input[type="radio"] {
  display: none;
  &:not(:disabled) ~ label {
    cursor: pointer;
    border-color: rgba(219, 219, 219, 0.352);
  }
  &:disabled ~ label {
    background: rgb(53, 54, 95);
    border-color: rgb(200, 0, 0);
    box-shadow: none;
    cursor: not-allowed;
  }
}

.a-item > label {
  height: 100%;
  display: block;
  background: rgb(22, 23, 61);
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.75), 0px 0px 15px rgba(0, 0, 0, 0.75);
  border: 1px solid transparent;
  position: relative;
}

.a-item > input[type="radio"]:checked + label {
  background: rgb(28, 31, 123);
  border-color: rgba(255, 255, 255, 0.544);
  &::after {
    font-family: FontAwesome;
    border: 2px solid rgba(255, 255, 255, 0.544);
    position: absolute;
    content: "";
    top: -5px;
    right: -5px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: rgb(212, 255, 0);
    box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
  }

  .a-disable-scroll {
    overflow: hidden;
  }
}

/* Alert  */
.a-alert {
  background-color: rgb(22, 23, 61);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  margin: 30px 10px;
  padding: 20px 30px;
  padding-right: 40px;
  animation: alert-animation 0.35s ease-in-out;
  overflow: hidden;
}

.a-alert::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: red;
  animation: move-line 5s linear infinite;
}
.a-alert::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: rgb(255, 255, 255);
}

@keyframes move-line {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes alert-animation {
  0% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 0px;
    opacity: 1;
  }
}
